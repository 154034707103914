import React from "react"

import Layout from "../components/templates/layoutWithTitle"
import SEO from "../components/seo"
import Img from "gatsby-image"

import HeroCard from "../components/organisms/heros/cardGeneric"
import HeroQuestions from "../components/organisms/heros/questions"
import HeroCallToAction from "../components/organisms/heros/callToAction"

const IndexPage = ({ data }) => (
  <Layout fluid={true}>
    <SEO title="Current Offering" />

    <HeroCard>
      <h2>Current Offering</h2>
      <div className="mb-4"></div>

      <div className="px-md-5">
        <Img
          fluid={data.chart.childImageSharp.fluid}
          className="m-0 mb-2 m-md-5"
        />
      </div>

      <h3>Overview</h3>
      <p>
        Spere Financial notes allow investors to participate in the rapidly growing financial division of a company with
        over 40 years of experience in the energy management, security, and HVAC industries.
      </p>
      <p>
        The current COVID-19 pandemic has created extraordinary demand for the company’s products and services, including
        new air-purifying systems attached to existing HVAC systems. This, combined with the capital constraints being
        felt by the company’s restaurant and retail clients, make it a perfect time to expand Spere Financial’s
        shared-savings energy management program. The capital raised with these notes will enable the company to
        expand and provide attractive and profitable offerings to existing and new customers. The company expects
        profit margins to increase significantly over the next three to five years.
      </p>
      <p>
        The current offering is for 5-year amortized notes at 10% annual interest. This is an ongoing offering with no
        specific closing date, though we expect to be lowering the interest rate in the near future as we attract larger
        amounts of capital. Join us now for this great investment opportunity!
      </p>
    </HeroCard>

    <HeroQuestions />
    <HeroCallToAction />
  </Layout>
)


export const pageQuery = graphql`
  query {
    chart: file(relativePath: { eq: "chart_energy_savings.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
